@import "../../../../styles/app-variables.scss";
@import "../steps-common.scss";

.button-group {
  width: 200px;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: auto;
}

.button-item {
  flex: 1 100%;
}
.pad-30 {
  padding: 30px 0px;
  margin-top: 0px !important;
}
