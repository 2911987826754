@import "../../styles/app-variables.scss";

.step-progress {
  margin-bottom: 1.5rem;
  &__bar {
    display: flex;
    align-items: center;
    height: 2rem;
  }
  &__step {
    display: inline-block;
    transition-timing-function: ease-in-out;
    li {
      list-style: none;
      &::after {
        content: "";
        bottom: 0;
        height: 6px;
        width: 6px;
        display: block;
        border-radius: 10px;
        background-color: #083d4e;
        margin: 0px 15px;
        box-shadow: 0 0 1px #fff;
        -webkit-transition: height 0.3s ease;
        transition: height 0.3s ease;
        transition-timing-function: ease-in-out;
      }
      &.step-active {
        position: relative;
        transition-timing-function: ease-in-out;
        &::after {
          content: "";
          bottom: 0;
          height: 18px;
          width: 18px;
          display: block;
          border-radius: 10px;
          background-color: transparent;
          border: 2px solid #083d4e;
          padding: 2px;
          margin: 0px 15px;
          box-shadow: 0 0 1px #fff;
          -webkit-transition: height 0.3s ease;
          transition: height 0.3s ease;
          transition-timing-function: ease-in-out;
        }
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          bottom: 0;
          height: 10px;
          width: 10px;
          display: block;
          border-radius: 10px;
          background-color: #083d4e;
          box-shadow: 0 0 1px #fff;
          -webkit-transition: height 0.3s ease;
          transition: height 0.3s ease;
          transition-timing-function: ease-in-out;
        }
      }
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .step-progress {
    &__step {
      display: inline-block;
      li {
        list-style: none;
        &::after {
          margin: 0px 10px;
        }
        &.step-active {
          position: relative;
          &::after {
            margin: 0px 10px;
          }
        }
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
