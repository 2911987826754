.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  padding: 5vh 1rem 1.5rem 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

#profile {
  .dark-block {
    width: 30rem;
    @media (max-width: 576px) {
      width: 90vw;
    }
  }
}
