@import "../../../../styles/app-variables.scss";
@import "../steps-common.scss";

.credit-card-form {
  width: 500px;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  gap: $standard-flex-gap;
}

.credit-card-form-row {
  display: flex;
  flex-direction: row;
  flex: 1 100%;
  gap: $standard-flex-gap;
  position: relative;
}

.credit-card-form-column {
  flex: 1 100%;
}

.credit-card-form-country-column {
  width: 100%;
}

.credit-card-form-zip-column {
  width: 100%;
}
.font-black {
  color: #222;
  opacity: 0.7;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .credit-card-form {
    width: 350px !important;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
