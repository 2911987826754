@import "../../styles/app-variables.scss";

.cc-form-outer {
  width: 500px;
  margin: auto;
  margin-top: 50px;

  div {
    text-align: center;
  }

  .card-form {
    margin: 50px 0 20px 0;
  }
}

.text-medium {
  font-size: 1.2rem;
  color: $astra-primary-color;
}

.text-small {
  font-size: 0.8rem;
}

.card-form-button {
  margin-bottom: 30px;
  button {
    width: 335px;
    cursor: pointer;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 450;
    line-height: 38px; /* 211.111% */
    border-radius: $button-radius;
    height: 50px;
  }
}

.save-card-container {
  cursor: pointer;
  margin-bottom: 20px;

  label {
    cursor: pointer;
    margin-left: 10px;
  }
}

.saved-cards-container {
  margin-top: 20px;
  padding: 8px 0;
  border-radius: 10px;
  border: 1px solid #666666;

  .saved-cards-label {
    font-size: 1.5rem;
  }
  .saved-card {
    display: flex;
    flex-direction: row;

    .saved-card-select {
      flex: 1;
    }

    .saved-card-number {
      flex: 2;
      text-align: left;
    }

    .saved-card-expire {
      flex: 2;
      text-align: left;
    }
    div {
      display: inline-block;
    }
  }
}
