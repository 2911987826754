@import "../../../../styles/app-variables.scss";
@import "../steps-common.scss";

.checkbox-list {
  &__item {
    margin-bottom: 0.5rem;
  }
  textarea {
    margin-top: 0.25rem;
  }
}
