@import "../../styles/app-variables.scss";

.select-container {
  width: 100%;
  select {
    padding: 0.5rem 1rem;
    width: 100%;
    font-size: $font-size-base;
    border-radius: $large-radius;
    border-right: 0.75rem solid #ffffff;
    @include soft-shadow;
    &:focus {
      outline: 3px solid $astra-tertiary-color;
    }
  }
}
