@import "../../global.scss";

.edit-field {
  margin-bottom: 10px;
  width: 30rem;
}

.label-text-field-container {
  margin-bottom: 10px;
  width: 30rem;
  background-color: #ffffff;
  border-radius: $default-radius;
  padding: 6px 10px 0 10px;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  &:focus-within {
    @include input-focus;
  }
}

.field {
  &__label {
    text-align: left;
    white-space: nowrap;
    opacity: 0.75;
    flex: 1;
  }

  &__value {
    text-align: right;
  }

  &__input {
    text-align: right;
    outline: none;
  }
}

@media (max-width: 576px) {
  .label-text-field-container {
    width: 90vw;
  }
}
