@import "../../styles/app-variables.scss";

.textbox-container {
  position: relative;
  min-width: 15rem;
  width: 100%;
  input {
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: $font-size-base;
    border-radius: $large-radius;
    background: #ffffff;
    color: #000000;
    position: relative;
    @include soft-shadow;
    &:focus {
      outline: 3px solid $astra-tertiary-color;
    }
  }
}

.card-img {
  position: absolute;
  right: -8%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  gap: 10px;
}

.cvc-img {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}

.card-img-alone {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}
