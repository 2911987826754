@import "../../../../styles/app-variables.scss";
@import "../steps-common.scss";

#step12 {
  .split-container {
    padding: 0 1rem 1rem 1rem;
  }

  .bio-links {
    margin-left: calc(5rem - 0.25rem); // to account for button padding
  }
}
