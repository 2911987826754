@import "../../styles/app-variables.scss";

.button-container {
  height: 40px;
  width: 80px;
  border: none;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  .button {
    width: 80px;
    height: 100%;
    color: #222;
  }
}

.button-active {
  background-color: #083d4e;
  color: white !important;
  border-radius: 10px;
}
