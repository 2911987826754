$primary-blue-color: #668bb4;

$font-color-main: $primary-blue-color;

$element-color-main: $primary-blue-color;

$standard-flex-gap: 10px;
$medium-flex-gap: 5px;
$small-flex-gap: 2px;

$standard-gradient: linear-gradient(
  180deg,
  #ffe26f -35.71%,
  #f7f7f7 25.43%,
  #f7f7f7 100%
);
$body-gradient: linear-gradient(
  180deg,
  #ffe26f -21.61%,
  #f7f7f7 21.56%,
  #cad5dd 100%
);

$main-font-family: "NeueHaasGroteskDisp";
$primary-font-family: $main-font-family;
// $secondary-font-family: "SF Pro";
$secondary-font-family: "Arial";

// COLORS //
$astra-dark-blue: #083d4e;
$astra-mid-blue: #436e7c;
$astra-light-blue: #b8cbc9;
$astra-lightest-blue: #dfe8e8;

$astra-primary-color: $astra-dark-blue;
$astra-primary-faded: $astra-mid-blue;
$astra-secondary-color: $astra-lightest-blue;
$astra-tertiary-color: $astra-light-blue;

// BORDER RADIUS //
$default-radius: 5px;
$large-radius: 10px;
$xl-radius: 25px;
$button-radius: 10px;

// TYPOGRAPHY //
$font-size-sm: clamp(0.8rem, 0.17vi + 0.76rem, 0.89rem);
$font-size-base: clamp(1rem, 0.34vi + 0.91rem, 1.19rem);
$font-size-md: clamp(1.25rem, 0.61vi + 1.1rem, 1.58rem);
$font-size-lg: clamp(1.56rem, 1vi + 1.31rem, 2.11rem);
$font-size-xl: clamp(1.95rem, 1.56vi + 1.56rem, 2.81rem);
$font-size-xxl: clamp(2.44rem, 2.38vi + 1.85rem, 3.75rem);
$font-size-xxxl: clamp(3.05rem, 3.54vi + 2.17rem, 5rem);

// BUTTONS //
$default-btn-width: 15rem;
$default-btn-height: 3rem;

// MIXINS //
// ...Shadows...
@mixin soft-shadow {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
}

// ...Focus(es)...
@mixin input-focus {
  outline: 3px solid $astra-tertiary-color;
}

@mixin dark-focus {
  outline: 3px solid $astra-primary-color;
}

@mixin light-focus {
  outline: 3px solid $astra-secondary-color;
}
