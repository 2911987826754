@import "../../styles/app-variables.scss";

.dark-block {
  .time-option {
    border-radius: $large-radius;
    background-color: #ffffff;
    padding: 10px;
    max-width: 6.5rem;
    color: #000000;
    flex: 1 0 6.5rem;
    text-align: center;
    cursor: pointer;
    @include soft-shadow;

    &.selected {
      background: $astra-primary-color;
      color: #ffffff;
    }
  }
}

.pill-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.25rem;

  .pill {
    background-color: $astra-secondary-color;
    color: #000000;
    margin: 0.25rem;
    padding: 0.4rem 1rem;
    border-radius: $xl-radius;
    font-size: $font-size-sm;
    line-height: 1;
  }
}

.availability-container {
  display: flex;
  flex-wrap: wrap;
  margin: -0.25rem;
  > * {
    margin: 0.25rem;
  }
}

.therapist-bio {
  display: flex;
  flex-direction: column;
  &__intro {
    display: flex;
    align-items: center;
  }
}
