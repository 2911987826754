@import "../../styles/app-variables.scss";

#home {
  .message-container {
    width: 100%;

    .empty-message {
      width: 100%;
      background: rgba(0, 0, 0, 0.075);
      border-radius: $default-radius;
      padding: 1rem;
      margin: 0.5rem auto 2rem auto;
    }
  }

  .row-center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin: -0.5rem;
  }

  .home-block {
    padding: 0.5rem;
    flex: 1 0 17.5rem;
  }
}
