@import "../../styles/app-variables.scss";

$image-size: 60px;
$chat-width: 30vw;

.chat-container {
  width: $chat-width;
  min-width: 400px;
  margin: auto;
  margin-top: 50px;
  background-color: $astra-secondary-color;

  .chat-your-messages {
    padding: 20px 0;
    text-align: center;
    font-family: "Neue Haas Grotesk Display Pro";
    font-size: 24px;
    font-style: normal;
    font-weight: 450;
    line-height: 38px;
  }
}

.chat-button-go-back {
  margin: 10px;
}

.chat.select {
  .channel-list {
    display: block;
    padding: 50px 0;
  }

  .channel-display {
    display: none;
  }
}

.chat.active {
  .channel-list {
    display: none;
  }

  .channel-display {
    display: block;
    height: 80vh;
    width: $chat-width;
  }
}

.channel-display {
  .message-outgoing {
    text-align: right;
  }

  .message-incoming {
    text-align: left;
  }
}

.message-outer {
  .message-time {
    color: #222;
    text-align: center;
    font-family: "Neue Haas Grotesk Display Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: 38px;
  }
}

.message-item {
  display: flex;
  flex-direction: row;

  font-family: "Neue Haas Grotesk Display Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 18px;

  .message-time {
    text-align: center;
    flex: 1 100%;
  }
  .message-item-inner {
    padding: 12px;
    border-radius: 6px;
    margin-bottom: 15px;
  }

  &.message-incoming {
    justify-content: left;
    .message-item-inner {
      background-color: white;
    }
  }

  &.message-outgoing {
    justify-content: right;
    .message-item-inner {
      background-color: $astra-primary-color;
      color: white;
    }
  }

  &.message-admin {
    justify-content: center;
    .message-item-inner {
      background-color: #f5f5f5;
    }
  }

  .message-file {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;

    .message-file-icon {
      flex: 1;
    }

    .message-file-name {
      flex: 10;
    }
  }
}

.channel-list-item {
  display: flex;
  flex-direction: row;
  cursor: pointer;

  .channel-item-icon-open {
    flex: 1;
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      height: 100%;
    }
  }

  .channel-item-icon-provider {
    flex: 1;
    padding: 10px 20px;

    .provider-image {
      width: $image-size;
      height: $image-size;

      img {
        border-radius: $image-size;
      }
    }
  }

  .channel-item-info {
    flex: 4;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    div {
      text-align: left;
      width: 100%;
    }
  }

  .chat-name {
    color: #000;
    font-family: "Neue Haas Grotesk Display Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }

  .chat-time {
    color: #222;
    font-family: "Neue Haas Grotesk Display Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: 22px;
  }
}

.chat-header {
  height: 170px;
  display: flex;
  flex-direction: row;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  .chat-header-back-button {
    cursor: pointer;
  }

  .chat-header-left,
  .chat-header-right {
    flex: 1;
    display: flex;
    align-items: center;
  }
  .chat-header-middle {
    flex: 10;
    text-align: center;
    color: #222;
    font-family: "Neue Haas Grotesk Display Pro";
    font-size: 20px;
    font-style: normal;
    font-weight: 450;
    line-height: 38px;
    padding-top: 45px;

    img {
      width: $image-size;
      height: $image-size;
      border-radius: $image-size;
      margin: auto;
    }
  }
}

/* Sendbird style overrides */
.sendbird-conversation {
  background-color: $astra-secondary-color !important;
}

.sendbird-channel-list {
  margin: auto;
  background-color: white !important;

  .sendbird-channel-preview__content__lower__last-message {
    display: none;
  }

  .sendbird-channel-preview__content__upper__last-message-at {
    position: relative;
    top: 30px;
    left: -160px;
  }

  .sendbird-channel-preview__content__upper__header__total-members {
    display: none;
  }

  .sendbird-channel-preview__content__upper__header__channel-name {
    position: relative;
    top: 8px;
  }

  .sendbird-channel-preview__content__upper__header {
    overflow: visible;
  }

  .sendbird-channel-preview:hover {
    .sendbird-channel-preview__content__upper__last-message-at {
      display: block !important;
    }
  }

  .sendbird-icon.sendbird-icon-more {
    display: none;
  }
}

.sendbird-channel-list__header {
  display: none;
}
