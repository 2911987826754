@import "../../styles/app-variables.scss";

.calendar-container {
  color: $font-color-main;
  width: 100%;
  margin: auto;
}

.month-container {
  margin-top: 32px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dates-container {
  display: flex;
  margin: auto;
  width: 100%;
}

.button-month {
  padding: 10px;
}

.button-dates {
  flex: 1 100%;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.month-name {
  width: 100px;
  color: #222;
}

.date-display {
  flex: 1 100%;
  min-width: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 80px;
  border-radius: 10px;
  border: none;
  margin: 0 5px;
  padding: 3px 0;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
  color: #222;
}

.date-display-active {
  background-color: white;
  cursor: pointer;
}

.date-display-selected {
  background-color: #083d4e;
  color: white;
  cursor: pointer;
}

.date-display-inactive {
  background-color: #fff;
  opacity: 0.5;
  pointer-events: none;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .date-scroll {
    flex: 1;
    display: flex;
    overflow: auto;
  }
  .dates-container {
    min-height: min-content;
    width: auto;
    position: relative;
    .date-display-inactive,
    .date-display-selected,
    .date-display-active {
      // width: 100px;
    }
  }
  .button-prev-dates {
    display: none;
  }
  .button-dates {
    width: 50px !important;
    // position: absolute;
    // top: 25%;
    // &.btn-right {
    //   right: 0px;
    // }
    // &.brn-left {
    //   left: 0px;
    // }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .calendar-container {
    width: 90%;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
