@import "../../styles/app-variables.scss";

.checkbox-container {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: row;
  position: relative;
  flex-wrap: nowrap;
  align-items: flex-start;

  input[type="checkbox"] {
    // hide default checkbox
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #ffffff;
    z-index: 1;
    position: absolute;
    height: 1.25rem;
    width: 1.25rem;
    top: 0.7rem;
    left: 0.75rem;
    border: 3px solid white;
    border-radius: $default-radius;
    outline: 1px solid $astra-primary-color;
    color: #000000;
    cursor: pointer;
    &:checked {
      background-color: $astra-primary-color;
    }
  }

  label {
    background: white;
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 2.75rem;
    border-radius: $large-radius;
    font-size: $font-size-base;
    cursor: pointer;
    &.checked {
      outline: 1px solid $astra-primary-color;
    }
  }

  &:focus-within {
    label {
      outline: 3px solid $astra-tertiary-color;
    }
  }
}
