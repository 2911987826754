@import "../../styles/app-variables.scss";

.back-button {
  position: absolute;
  top: 1rem;
  left: 5vw;
}

.button-footer {
  background-color: $astra-tertiary-color;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
}
