@import "../../../../styles/app-variables.scss";
@import "../steps-common.scss";

.filter-row {
  display: flex;
  justify-content: space-between;
}

.therapist-list {
  display: flex;
  flex-direction: column;
  gap: $standard-flex-gap;

  height: calc(100vh - 445px);
  overflow-y: auto;
}

.therapist-list-item {
  flex: 1 100%;
}

.therapist-availability-label {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 18px;
  color: #222;
}

.therapist-availability-label-right {
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 38px;
  color: #083d4e;
}

.width-700 {
  width: 782px !important;
}
.width-650 {
  width: 650px !important;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .width-700,
  .width-650 {
    width: 350px !important;
  }
  .therapist-list {
    height: 100%;
    margin-bottom: 40%;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
