.step-container {
  width: 100%;
}

// .step-wrapper {
//   height: 100%;
// }

// .step-header {
//   color: #222;
//   text-align: center;
//   font-size: 36px;
//   font-style: normal;
//   font-weight: 450;
//   line-height: normal;
// }

// .step-subhead {
//   color: #222;
//   text-align: center;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 450;
//   line-height: 22px;
//   // max-width: 400px;
//   margin: auto;
//   margin-top: 12px;
//   white-space: nowrap;
// }

// .step-text {
//   justify-content: center;
//   color: #222;
//   text-align: center;
//   font-size: 24px;
//   font-style: normal;
//   font-weight: 450;
//   line-height: 20px; /* 135.714% */
//   opacity: 0.8;
//   padding: 32px 0;
// }

// .step-medium-text {
//   justify-content: center;
//   color: #222;
//   text-align: center;
//   font-family: $main-font-family; /* 135.714% */
//   opacity: 0.8;
//   padding: 32px 0;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 450;
//   line-height: 22px;
// }

// .step-small-text {
//   justify-content: center;

//   color: #222;
//   text-align: center;
//   font-family: $main-font-family;
//   font-size: 11px;
//   font-style: normal;
//   font-weight: 300;
//   line-height: 38px; /* 135.714% */

//   opacity: 0.8;
// }

// .step-body {
//   width: 500px;
//   margin: 20px auto;
//   margin-top: 40px;
//   color: $font-color-main;
//   font-family: $main-font-family;
//   margin-bottom: 20%;
// }

// .step-body-line {
//   display: flex;
//   gap: $standard-flex-gap;
//   padding: 5px 0;
// }

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  // .step-body {
  //   width: 350px;
  // }
  // .step-header {
  //   font-size: 26px;
  //   padding: 0px 30px;
  // }
  // .step-subhead {
  //   width: 80%;
  //   word-wrap: break-word;
  //   text-wrap: wrap;
  // }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
